<template>
  <div id="welcome">
    <!-- <h3 v-if="user">Hi, {{user.first_name}} {{user.last_name}}</h3> -->
    <h3>Welcome!</h3>
  </div>
</template>

<script>
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Welcome",
  // props: ['user']
  // data() {
  //   return { user: null };
  // },
  computed: {
    ...mapGetters(["user/user"]),
  },
  // async created() {
  // const response = await axios.get("user", {
  //   headers: { Authorization: "Bearer" + localStorage.getItem("token") },
  // });

  // const response = await axios.get("user");
  // this.user=response.data;
  // console.log(response);
  // },
};
</script>

<style></style>
